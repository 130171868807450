import React from 'react';
import settings from '../../../settings';
import TrackItem from '../../components/trackitem';

const MultipleTrack = (props) => {
  const spartanburg = `${settings.IMAGES_BASE_URL}/v2/images/spartanburg-overhead-v2-track.jpg`;
  const thermalca = `${settings.IMAGES_BASE_URL}/v2/images/thermal-overhead-v2-track.jpg`;
  return (
    <section className="track multiple_track">
      <div className="row">
        <div className="col">
          <TrackItem image={spartanburg} title="SPARTANBURG, SOUTH CAROLINA" text="Fast turns, a back straight perfect for speed exercises and a polished wet concrete skid pad make our Spartanburg track the perfect place to hone your driving skills." />
        </div>
        <div className="col">
        <TrackItem image={thermalca} title="THERMAL, CALIFORNIA" text="Set against the backdrop of a beautiful mountain range, The Thermal Club is an exclusive track that offers three unique layouts, plus our own challenge course to encourage maximum thrills." />
        </div>
      </div>
    </section>
  );
};
export default MultipleTrack;
